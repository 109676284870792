import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import RegisterDetailPage from "./pages/RegisterDetailPage";
import PageShiftDetailsPage from "./pages/PageShiftDetailsPage";
import DetailedStoppingAnalysisPage from "./pages/DetailedStoppingAnalysisPage";
import InputsPage from "./pages/InputsPage";
import {
  createTheme,
  CssBaseline,
  GlobalStyles,
  ThemeProvider,
} from "@mui/material";
import ReportsPage from "./pages/ReportsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/shift-details/:shiftId",
    element: <PageShiftDetailsPage />,
  },
  {
    path: "/detailed-stopping-analysis",
    element: <DetailedStoppingAnalysisPage />,
  },
  {
    path: "/inputs/:accessToken",
    element: <InputsPage />,
  },
  {
    path: "/registers",
    element: <RegisterDetailPage />,
  },
  {
    path: "/reports/:shiftId",
    element: <ReportsPage />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const defaultTheme = createTheme({
  components: {
    MuiBackdrop: {
      styleOverrides: {
        root: {
          backgroundColor: "rgba(0,0,0,0.3)",
          backdropFilter: "blur(16px)",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          "& .MuiTablePagination-input": {
            color: "#FBFCFC",
          },
          "& svg": {
            color: "#FBFCFC",
          },
          background: "#202020",
          border: "1px solid #444444",
          "& .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-columnHeader:focus-within":
            {
              outline: "none",
            },
        },
        toolbarContainer: {
          paddingRight: `${15} !important`,
          paddingLeft: `${3.25} !important`,
        },
        columnHeaders: {
          maxHeight: "54px !important",
          minHeight: "54px !important",
          lineHeight: "24px !important",
          backgroundColor: "#1B1B1B",
        },
        columnHeader: {
          color: "#FBFCFC",
          height: "54px",
          "&:not(.MuiDataGrid-columnHeaderCheckbox)": {
            padding: 4,
            "&:first-of-type": {
              paddingLeft: 5,
            },
          },
          "&:last-of-type": {
            paddingRight: 5,
          },
        },
        iconButtonContainer: {
          color: "#FBFCFC !important",
        },
        columnHeaderTitleContainer: {
          padding: 0,
        },
        columnHeaderTitle: {
          fontWeight: 700,
          fontSize: "0.85rem",
          letterSpacing: "0.21px",
          textTransform: "capitalize",
        },
        iconSeparator: {
          color: "#444444",
        },
        columnSeparator: {
          color: "#444444",
          ".MuiDataGrid-sortIcon": {
            color: "#FBFCFC !important",
          },
        },
        virtualScrollerRenderZone: {
          "& .MuiDataGrid-row": {
            maxHeight: "50px !important",
            minHeight: "50px !important",
          },
        },
        row: {
          "&:hover": {
            backgroundColor: "#2e2e2e",
          },
          "&.Mui-selected": {
            backgroundColor: "#171717",
            "&:hover": {
              backgroundColor: "#181818",
            },
          },
          "&:last-child": {
            "& .MuiDataGrid-cell": {
              borderBottom: 0,
            },
          },
        },
        cell: {
          maxHeight: "50px !important",
          minHeight: "50px !important",
          lineHeight: "20px !important",
          borderColor: "#444444",
          "&:not(.MuiDataGrid-cellCheckbox)": {
            padding: 4,
            "&:first-of-type": {
              paddingLeft: 5,
            },
          },
          "&:last-of-type": {
            paddingRight: 5,
          },
          "&:focus, &:focus-within": {
            outline: "none",
          },
        },
        editInputCell: {
          padding: 0,
          color: "#FBFCFC",
          "& .MuiInputBase-input": {
            padding: 0,
          },
        },
        footerContainer: {
          minHeight: "50px !important",
          "& .MuiTablePagination-toolbar": {
            minHeight: "50px !important",
          },
          "& .MuiTablePagination-displayedRows, & .MuiTablePagination-selectLabel":
            {
              color: "#FBFCFC",
            },
        },
      },
      defaultProps: {
        rowHeight: 50,
        columnHeaderHeight: 54,
      },
    },
    MuiAppBar: {
      styleOverrides: {
        colorTransparent: {
          background: "transparent !important",
          boxShadow: "none",
        },
      },
    },
  },
  palette: {
    primary: {
      light: '#000',
      main: '#fff',
      dark: '#f0f3f4'
    },
  },
  typography: {
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
});

root.render(
  <React.StrictMode>
    <ThemeProvider theme={defaultTheme}>
      <div style={{ display: "block", maxWidth: "100dvw !important",   height: "100dvh"
 }}>
        <GlobalStyles
          styles={{ ul: { margin: 0, padding: 0, listStyle: "none" } }}
        />
        <CssBaseline />
        <RouterProvider router={router} />
      </div>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
